export default {
    size: {
        type: String,
        validator(value) {
            ["xs", "md", "lg", "xl"].includes(value);
        },
        default: "md",
    },
    type: {
        type: String,
        validator(value) {
            ["rounded", "sm-round", "square"].includes(value);
        },
        default: "rounded",
    },
    hasHeader: {
        type: Boolean,
        default: false,
    },
    hasFooter: {
        type: Boolean,
        default: false,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    canClose: {
        type: Boolean,
        default: true,
    },
    canClickOutsideClose: {
        type: Boolean,
        default: true,
    },
    saving: {
        type: Boolean,
        default: false,
    },
};
