<script setup>
import { computed, ref, onMounted } from "vue";

import { BaseLoader } from "@/shared";

import { IconXmark } from "../../icons";
import BaseButton from "../button/BaseButton.vue";

import modalProps from "./config/props";

const props = defineProps({
    ...modalProps,
});

const emit = defineEmits(["save", "show", "hide", "shown"]);

const modal = ref(null);
const showModal = ref(false);

const modalClasses = computed(() => {
    const modalType = `base-modal__${props.type}`;
    const modalSize = `base-modal__${props.size}`;

    const arrayClasses = ["base-modal"];

    props.type && arrayClasses.push(modalType);
    props.size && arrayClasses.push(modalSize);

    return arrayClasses;
});

const show = () => {
    document.body.style.overflowY = "hidden";

    emit("show");
    showModal.value = true;
};
const hide = () => {
    document.body.style.overflowY = "auto";

    emit("hide");
    showModal.value = false;
};

onMounted(() => {
    document.addEventListener("click", (e) => {
        if (
            showModal.value &&
            modal.value &&
            props.canClose &&
            props.canClickOutsideClose &&
            e.target !== modal.value &&
            e.target === modal.value.parentNode
        )
            hide();
    });
});

const handleSave = () => {
    emit("save");
};

defineExpose({ show, hide });
</script>

<template>
    <teleport to="body">
        <transition name="modal" @after-enter="emit('shown')">
            <div v-if="showModal" class="base-modal__wrapper" v-bind="$attrs">
                <div ref="modal" :class="modalClasses">
                    <div v-if="hasHeader" class="base-modal__header">
                        <h6 class="base-modal__heading">
                            <slot name="heading"></slot>
                        </h6>
                        <BaseButton
                            v-if="canClose"
                            @click="hide"
                            variant="default"
                            type="circle"
                        >
                            <IconXmark />
                        </BaseButton>
                    </div>
                    <slot />
                    <div v-if="hasFooter" class="base-modal__footer">
                        <slot name="footer">
                            <BaseButton
                                variant="primary"
                                class="base-modal__save-btn"
                                @click="handleSave"
                                :disabled="saving"
                            >
                                Сохранить
                            </BaseButton>
                        </slot>
                    </div>
                    <BaseLoader v-if="isLoading" />
                </div>
            </div>
        </transition>
    </teleport>
</template>

<style lang="scss" scoped>
.base-modal {
    --modal-padding: 20px 30px 30px;
    position: relative;

    width: var(--modal-width);
    padding: var(--modal-padding);

    margin: calc(var(--header-height) + 20px) auto 30px;

    overflow: hidden;

    background-color: #fff;
    border-radius: var(--modal-border-radius);
    box-shadow: var(--default-box-shadow);

    &__header {
        display: flex;

        align-items: center;

        justify-content: space-between;

        margin-bottom: 30px;
    }

    &__heading {
        font-size: 16px;
        font-weight: 700;
    }

    &__footer {
        display: flex;

        margin-top: 30px;
    }

    &__save-btn {
        margin-left: auto;
    }

    &__wrapper {
        position: fixed;
        inset: 0;
        z-index: var(--zi-modal);

        overflow: hidden auto;

        background-color: rgba($color: #2f4050, $alpha: 65%);
    }

    &__sm {
        --modal-width: 300px;
    }

    &__md {
        --modal-width: 550px;

        @media (max-width: 580px) {
            --modal-width: 95%;
        }
    }

    &__lg {
        --modal-width: 800px;

        @media (max-width: 842px) {
            --modal-width: 95%;
        }
    }

    &__xl {
        --modal-width: 1140px;

        @media (max-width: 1200px) {
            --modal-width: 95%;
        }
    }

    &__square {
        --modal-border-radius: 0;
    }

    &__sm-round {
        --modal-border-radius: var(--default-small-border-radius);
    }

    &__rounded {
        --modal-border-radius: var(--default-border-radius);
    }
}

.modal {
    &-enter-from,
    &-leave-to {
        opacity: 0;

        transition: opacity 0.3s ease-in-out;

        .base-modal {
            top: 0;

            transition: top 0.3s ease-in-out;
        }
    }

    &-enter-to,
    &-leave-from {
        opacity: 1;

        transition: opacity 0.3s ease-in-out;

        .base-modal {
            top: var(--modal-position-top);

            transition: top 0.3s ease-in-out;
        }
    }
}
</style>
