import { getDataMapper, mapField, parseISO } from "@/shared";

export const mapHumanTariffFromServer = getDataMapper({
    autoPayment: mapField("auto_payment", Boolean),
    convertedAt: mapField("converted_at"),
    convertedToId: mapField("converted_to_id"),
    cost: mapField("cost", String),
    directorId: mapField("director_id", Number),
    hasPaymentMethod: mapField("has_payment_metod", Boolean),
    haveAccessRepairService: mapField("have_access_repair_service", Boolean),
    haveAccessStore: mapField("have_access_store", Boolean),
    haveAccessSalary: mapField("have_access_salary", Boolean),
    id: mapField("id", Number),
    isFree: mapField("is_free", Boolean),
    limitClients: mapField("limit_clients", Number),
    limitCompany: mapField("limit_company", Number),
    limitEmployees: mapField("limit_employees", Number),
    limitInventories: mapField("limit_inventories", Number),
    limitPoints: mapField("limit_points", Number),
    period: mapField("period", String),
    periodString: mapField("period_string", String),
    tariffPlanId: mapField("tariff_plan_id", Number),
    parentTariffPlanId: mapField("parent_tariff_plan_id", Number),
    timeDisactive: mapField("time_disactive", String),
    timeDisactiveDate: mapField("time_disactive_date", String),
    timeEnd: mapField("time_end", parseISO),
    timeStart: mapField("time_start", parseISO),
    title: mapField("title", String),
    updatedAt: mapField("updated_at", String),
});
