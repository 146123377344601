import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

import { isArray, GENERAL_ROUTE_NAMES } from "@/shared";

import { getActiveTariff } from "../api/getActiveTariff";
import { getTariffsList } from "../api/getTariffsList";

export const useTariffStore = defineStore("tariffStore", () => {
    const router = useRouter();

    const tariff = ref(null);
    const statistic = ref(null);
    const isFree = ref(false);
    const referral = ref(null);
    const autopayment = ref(false);
    const hasPaymentMethod = ref(false);
    const tariffsList = ref(null);

    const setTariff = async () => {
        const {
            tariff: currentTariff,
            statistic: currentStatistic,
            isFree: currentIsFree,
            referral: currentReferral,
            autopayment: currentAutopayment,
            hasPaymentMethod: currentHasPaymentMethod,
        } = await getActiveTariff();

        tariff.value = currentTariff;
        statistic.value = currentStatistic;
        isFree.value = currentIsFree;
        referral.value = currentReferral;
        autopayment.value = currentAutopayment;
        hasPaymentMethod.value = currentHasPaymentMethod;
    };

    const setStatistic = (s) => {
        statistic.value = s;

        if (s.daysLeft === 0) {
            router.push({ name: GENERAL_ROUTE_NAMES.BUY_TARIFF });
        }
    };

    const recalculateTariffsWithBonuses = (isUseBonus) => {
        if (
            isUseBonus &&
            isArray(tariffsList.value) &&
            referral.value.bonus > 0
        ) {
            tariffsList.value.forEach(({ children }) => {
                children.forEach((child) => {
                    if (referral.value.bonus > child.cost) {
                        child.cost -=
                            referral.value.bonus -
                            (referral.value.bonus - child.cost + 1);
                    } else {
                        child.cost -= referral.value.bonus;
                    }
                });
            });
        }
    };

    const fetchTariffsList = async (promocode, isUseBonus = false) => {
        const { tariffs } = await getTariffsList(promocode);

        tariffsList.value = tariffs;

        if (isUseBonus) {
            recalculateTariffsWithBonuses(isUseBonus);
        }
    };

    return {
        tariff,
        statistic,
        tariffsList,
        isFree,
        referral,
        autopayment,
        hasPaymentMethod,
        setTariff,
        setStatistic,
        fetchTariffsList,
        recalculateTariffsWithBonuses,
    };
});
